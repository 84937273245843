var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      (!_vm.loadingValue || _vm.lazy) && !_vm.errorValue
        ? [
            _vm._t(
              "default",
              function() {
                return [
                  _c(
                    _vm.childComponent,
                    _vm._g(
                      _vm._b(
                        { tag: "component" },
                        "component",
                        _vm.childAttributes,
                        false
                      ),
                      _vm.childListeners
                    )
                  )
                ]
              },
              null,
              _vm.context
            )
          ]
        : _vm._e(),
      _vm.loadingValue
        ? [
            _vm._t(
              "loading",
              function() {
                return [_c("Loading")]
              },
              null,
              _vm.context
            )
          ]
        : _vm.errorValue
        ? [
            _vm._t(
              "error",
              function() {
                return [
                  _c("ErrorAlert", {
                    attrs: { dismissible: "" },
                    on: { dismissed: _vm.retry },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [_vm._v(" " + _vm._s(_vm.errorValue) + " ")]
                        },
                        proxy: true
                      },
                      {
                        key: "dismiss",
                        fn: function() {
                          return [
                            _c("BIconArrowRepeat", {
                              attrs: { title: "Retry" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._t("after-error", null, null, _vm.context)
                ]
              },
              null,
              _vm.context
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }